import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'id',
    'presentButton',
    'absentButton',
    'presence',
    'name',
    'loc',
    'updatedBy',
    'updatedAt',
    'loggedLabel',
    'comment',
  ]

  connect () {
    this.updatePresence(this.presence)
    this.updateLoggedBy(this.updatedAt, this.updatedBy)
  }

  parsedValue (value) {
    if (value === 'null' || value === 'undefined') {
      return null
    }
    return value
  }

  get dataTable () {
    if (!window.jQuery) return null
    const table = this.element.closest('table')
    if (!table || !$.fn.dataTable.isDataTable(table)) return null
    return $(table).DataTable()
  }

  get presence () {
    return this.parsedValue(this.data.get('presence'))
  }

  set presence (value) {
    this.data.set('presence', value)
  }

  get updatedBy () {
    return this.parsedValue(this.data.get('updatedBy'))
  }

  set updatedBy (value) {
    this.data.set('updatedBy', value)
  }

  get updatedAt () {
    return this.parsedValue(this.data.get('updatedAt'))
  }

  set updatedAt (value) {
    this.data.set('updatedAt', value)
  }

  updateLoggedBy (updatedAt = '', updatedBy = '', tz = null) {
    const table = this.dataTable
    let formattedDate = ''
    if (!updatedBy || !updatedAt) {
      updatedBy = updatedAt = ''
    }
    this.updatedBy = updatedBy
    this.updatedAt = updatedAt

    if (updatedAt) {
      const date = new Date(this.updatedAt)

      if (tz == null) {
        formattedDate = date.toLocaleString()
      } else {
        formattedDate = date.toLocaleString('en-US', { timeZone: tz })
      }
    }


    if (this.hasUpdatedByTarget) {
      this.updatedByTarget.innerText = updatedBy
    }
    if (this.hasUpdatedAtTarget) {
      this.updatedAtTarget.innerText = formattedDate
    }

    if (table) {
      const columnCount = table.columns().nodes().length - 1
      const hiddenUpdatedBy = columnCount - 2
      const hiddenUpdatedAt = columnCount - 1
      table.cell(this.element, hiddenUpdatedBy).data(updatedBy)
      table.cell(this.element, hiddenUpdatedAt).data(formattedDate)
    }

    if (!this.hasLoggedLabelTarget) return

    if (!this.updatedBy || !this.updatedAt) {
      this.loggedLabelTarget.innerHTML = ''
      this.loggedLabelTarget.parentElement?.classList?.add?.('tw-hidden')
      return
    }

    this.loggedLabelTarget.innerHTML = `${this.updatedBy} at ${formattedDate}`
    this.loggedLabelTarget.parentElement?.classList?.remove?.('tw-hidden')
  }

  togglePresence (event) {
    const id = this.data.get('id')
    let newPresence
    if (event.currentTarget.value === 'present') {
      newPresence = this.presence === 'present' ? 'unrecorded' : 'present'
    } else {
      newPresence = this.presence === 'absent' ? 'unrecorded' : 'absent'
    }
    const flash = document.getElementById('flash-message')
    const that = this
    flash.innerHTML = ''
    const options = {
      method: 'PUT',
      credentials: 'include',
      mode: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ patient_attendance: { presence: newPresence } }),
    }
    document.getElementById('ajax_working').style.display = 'block'
    fetch('/attendances/' + id, options)
      .then(function (response) {
        if (!response.ok) {
          throw new Error(response.statusText)
        } else {
          return response.json()
        }
      })
      .then(function (json) {
        that.updatePresence(json.presence)
        that.updateLoggedBy(json.updated_at, json.updated_by, window.Kipu?.globals?.timeZoneLong)
      })
      .catch(function (error) {
        flash.innerHTML = '<div class="notification-error mbottom1em"><h2>Errors Found</h2><p>' + error + '</p></div>'
      })
      .finally(() => {
        document.getElementById('ajax_working').style.display = 'none'
        this.dispatchUpdate()
      })
  }

  openModal () {
    const id = this.data.get('id')
    document.getElementById('ajax_working').style.display = 'block'
    fetch(`/attendances/${id}/modal`)
      .then(function (response) {
        if (!response.ok) {
          throw new Error(response.statusText)
        } else {
          return response.text()
        }
      })
      .then(function (html) {
        document.getElementById('custom-order-dialog').innerHTML = html
        if (window.jQuery) {
          window.jQuery('#custom-order-dialog').dialog({
            title: 'Edit Attendance',
            modal: true,
            resizable: true,
            width: '60%',
          })
        }
      })
      .catch(function (error) {
        document.getElementById('flash-message').innerHTML = error
      })
      .finally(function () {
        document.getElementById('ajax_working').style.display = 'none'
      })
  }

  deleteAttendance () {
    const id = this.data.get('id')
    const that = this
    const options = {
      method: 'delete',
      credentials: 'include',
      mode: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json',
      },
    }
    document.getElementById('ajax_working').style.display = 'block'
    fetch(`/attendances/${id}`, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText)
        } else {
          const table = that.dataTable
          if (table) table.row(that.element).remove().draw()
          else that.element.remove()
        }
      })
      .catch((error) => {
        document.getElementById('flash-message').innerHTML = error
      })
      .finally(() => {
        document.getElementById('ajax_working').style.display = 'none'
        this.dispatchUpdate()
      })
  }

  dispatchUpdate () {
    Array.from(document.getElementsByClassName('watch-attendance-table')).forEach((element) => {
      element.dispatchEvent(new Event('attendanceTableUpdated'))
    })
  }

  updatePresence (value) {
    this.presence = value
    if (this.hasPresentButtonTarget) {
      if (value === 'present') {
        this.presentButtonTarget.classList.add('present-status')
      } else {
        this.presentButtonTarget.classList.remove('present-status')
      }
    }

    if (this.hasAbsentButtonTarget) {
      if (value === 'absent') {
        this.absentButtonTarget.classList.add('absent-status')
      } else {
        this.absentButtonTarget.classList.remove('absent-status')
      }
    }

    const presenceText = `${value.charAt(0).toUpperCase()}${value.slice(1)}`

    if (this.hasPresenceTarget) {
      this.presenceTarget.innerText = presenceText
    }

    const table = this.dataTable
    if (table) {
      const columnCount = table.columns().nodes().length - 1
      const hiddenPresence = columnCount - 4
      table.cell(this.element, hiddenPresence).data(presenceText)
    }
  }

  updateComment (value) {
    this.comment = value

    if (this.hasCommentTarget) {
      this.commentTarget.innerText = value
    }

    const table = this.dataTable
    if (table) {
      const columnCount = table.columns().nodes().length - 1
      const hiddenComment = columnCount - 5
      table.cell(this.element, hiddenComment).data(value)
    }
  }

  updateAbsenceReason (value) {
    this.absenceReason = value

    if (this.hasAbsenceReasonTarget) {
      this.absenceReasonTarget.innerText = value
    }

    const table = this.dataTable
    if (table) {
      const columnCount = table.columns().nodes().length - 1
      const hiddenAbsenceReason = columnCount - 3
      table.cell(this.element, hiddenAbsenceReason).data(value)
    }
  }
}
