import { Controller } from 'stimulus'
import { customFetch } from '@/lib/customFetch'

export default class extends Controller {
  connect() {
    this.cachedQuery = null
    this.generate()
  }

  get hasFilters() {
    const keys = ['loc_name', 'program_id', 'search']
    const query = new URLSearchParams(window.location.search)
    return keys.some(key => query.has(key))
  }

  generate() {
    if (!this.data.has('url')) return this.legacyGenerate()

    /* if using the new API, fetch the totals at most once per date/filter combo */
    const query = new URLSearchParams(window.location.search)
    const url = new URL(this.data.get('url'), window.location)
    const keys = ['date', 'loc_name', 'program_id', 'search', 'show_deleted']

    keys.forEach(key => {
      if (query.has(key)) url.searchParams.set(key, query.get(key))
    })

    this.setDisplay()

    if (this.cachedQuery === url.searchParams.toString()) return

    this.cachedQuery = url.searchParams.toString()
    this.renderLevels([], 'Loading...')

    /*
      instead of building from a limited set of data that is displayed in a
      Paginated dataset, fetch the full totals from the API
    */
    customFetch(url, { json: true })
      .then(response => response.json())
      .then(data => this.renderLevels(data?.totals || []))
      .catch(err => {
        logger.error(err)
        this.renderLevels([], 'Could Not Load Data')
      })
  }

  setDisplay() {
    const stored = localStorage.getItem('attendance-settings')
    const settings = stored ? JSON.parse(stored) : {}

    if (settings.showSummary === false) {
      this.element.classList.add('tw-hidden')
    } else {
      this.element.classList.remove('tw-hidden')
    }
  }

  legacyGenerate() {
    this.setDisplay()
    const t = this.element
    let a = [].slice.call(document.getElementsByClassName('attendance-card')).filter(function(el) {
      return window.getComputedStyle(el).getPropertyValue('display') !== 'none'
    });
    if ( a.length == 0 ) {
      t.tBodies[0].innerHTML = "<tr><td colspan=5>Nothing to show.</td></tr>"
      return
    }
    t.tBodies[0].innerHTML = null
    const presences = ['present', 'absent', 'unrecorded']
    let tableLOCS = a.map(el => el.dataset.attendanceCardLoc).filter((value, index, self) => self.indexOf(value) === index).sort()
    let presenceTotals = {present: 0, absent: 0, unrecorded:0}
    tableLOCS.forEach((loc, index)=> {
      let loc_label = loc
      if (loc == '') {
        loc_label = 'None'
      }
      let row = t.tBodies[0].insertRow(index)
      let label = row.insertCell(0)
      label.innerText = loc_label // row label
      let total = 0
      presences.forEach( (val, index) => {
        let locCards = a.filter(el => el.dataset.attendanceCardLoc === loc)
        let count = locCards.filter(el => el.dataset.attendanceCardPresence === val ).length
        let cell = row.insertCell(index + 1)
        cell.innerText = count;
        total += count; // row total
        presenceTotals[val] += count // colum total
      });
      let cell = row.insertCell(-1)
      cell.innerText = total
    })
    let totalsRow = t.tBodies[0].insertRow(-1)
    let label = totalsRow.insertCell(0)
    label.innerText = "Totals"
    presences.forEach( (val, index) => {
      let count = presenceTotals[val]
      let cell = totalsRow.insertCell(index + 1)
      cell.innerText = count;
    });
    let cell = totalsRow.insertCell(-1)
    cell.innerText = Object.values(presenceTotals).reduce((n, el)=> n+=el)
  }

  renderLevels(levels, error) {
    Array.from(this.element.tBodies).forEach(tbody => tbody.remove())
    this.element.tFoot?.remove?.()

    const tbody = document.createElement('tbody')
    this.element.appendChild(tbody)

    if (!levels?.length || error) {
      const tr = document.createElement('tr')
      const td = document.createElement('td')
      td.colSpan = 5
      td.innerText = error?.message || error || 'Nothing to show.'
      td.classList.add('tw-text-center')
      tr.appendChild(td)
      tbody.appendChild(tr)
      return
    }

    const keys = ['loc_name', 'present', 'absent', 'unrecorded', 'total']

    if (levels[levels.length - 1].loc_name !== 'Totals') {
      let totals

      const idx = levels.findIndex(level => level.loc_name === 'Totals')

      if (idx !== -1) {
        totals = levels[idx]
        levels.splice(idx, 1)
      } else {
        totals = levels.reduce((acc, level) => {
          keys.slice(1).forEach(key => {
            acc[key] = (acc[key] || 0) + Number(level[key])
          })

          return acc
        }, {'loc_name': 'Totals'})
      }

      levels.push(totals)
    }

    const totals = levels.pop()
    if (this.hasFilters) {
      totals['loc_name'] = 'Totals (Filtered)'
    }

    levels.forEach((row) => {
      const tr = document.createElement('tr')

      keys.forEach(key => {
        const td = document.createElement('td')
        td.innerText = row[key]
        tr.appendChild(td)
      })

      tbody.appendChild(tr)
    })

    const tfoot = document.createElement('tfoot')
    const tr = document.createElement('tr')
    tr.classList.add('tw-sticky')
    tr.classList.add('tw-bottom-0')
    tr.classList.add('tw-bg-k-gray-100')
    keys.forEach(key => {
      const th = document.createElement('th')
      th.innerText = totals[key]
      tr.appendChild(th)
    })

    tfoot.appendChild(tr)
    this.element.appendChild(tfoot)
  }
}
